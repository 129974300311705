.checkout-menu {
  position: absolute;
  left: -300%;
  background-color: rgba(blue, 0.2);
  height: 0;
  // left: 300%;
  top: 0%;
  // transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  // font-family: "Fira Mono";
  z-index: 25;
  display: hidden;
  // border: 1px dashed red;
  // background-color: rgba(lightblue, 0.1);

  &__icon {
    position: absolute;
    top: 20%;
    right: 15%;
    font-size: 5rem;
    // transform: translate(-50%, -50%);
    text-align: center;

    p {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;
    }

    & > .cart-icon {
      position: relative;
    }

    .cart-items {
      color: white;
      position: absolute;
      font-size: 5px;
      top: 12.5%;
      left: 50%;
    }
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    right: 5%;
    text-align: right;
    z-index: 10;
    width: 75px;
    height: 75px;
  }

  &__checkbox:checked ~ &__card {
    transform: translate(-50%, 10vh);
  }

  &__card {
    transition: transform 0.5s cubic-bezier(0.83, 0, 0.17, 1);
    transform: translate(200%, 10vh);
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // row-gap: 10px;
    // display: grid;
    // grid-gap: 10px;
    // grid-template-rows: auto 1fr repeat(auto-fit, minmax(50px, 100%));
    justify-content: center;
    width: clamp(700px, 100%, 1000px);
    padding: 10px;
    background-color: rgba(#a4bf60, 1);
    border-radius: 2px;
    // height: 100%;

    & > * {
      width: 100%;
      border: 2px solid rgba(grey, 0.1);
      padding: 5px;
      border-radius: 6px;
      background-color: white;
    }
  }

  .card-title {
    text-align: center;

    &-main {
      font-size: 3rem;
      font-weight: light;
    }

    &-subtotal {
      font-size: 2rem;
      color: darken(darkgrey, 40%);
    }
  }

  .address {
    form {
      display: grid;
      grid-template-columns: auto 40fr 10fr;
      grid-gap: 10px;
      align-items: center;

      & > .address-label {
        margin: 0;
        padding: 0;
        color: darken(grey, 40%);
        opacity: 0.8;
        text-transform: uppercase;
        font-size: 1.5rem;
      }

      & > .address-input {
        font-size: 2rem;
        margin: 0;
        padding: 6px;
        // width: 120%;
        border: 0;
        outline: 0;
        background-color: rgba(lightgrey, 0.2);
      }

      & > .address-button {
        transform: translate(-10%, -5%);
        background: #fff;
        color: black;
        font-size: 1.5rem;
        box-shadow: 0 6px 10px 0 rgba(black, 0.1);
        border: 0;
        border-radius: 10px;
        background-color: rgba(#e7c569, 0.4);
        outline: 0;
        padding: 3px 20px;
        text-transform: uppercase;
        box-shadow: 0 3px 8px rgba(black, 0.6);
        transition: all 0.2s;

        &:active {
          background-color: lighten(rgba(#e7c569, 0.4), 3%);
          box-shadow: 0 3px 4px rgba(black, 0.8);
          transform: translate(-10%, 0);
        }
      }
    }
  }

  .products {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    overflow: auto;
    height: 100%;
    margin: 0;

    max-height: 600px;

    &-item {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: auto 4fr repeat(3, 1fr);
      align-items: center;
      border-bottom: 1px solid lightgrey;

      .product {
        &-image {
          width: 90%;
        }

        &-description {
          & > :first-child {
            font-weight: 500;
            font-size: 2rem;
            color: teal;

            &::after {
              content: "\a";
              white-space: pre;
            }
          }

          & > :last-child {
            font-size: 1.2rem;
            color: rgba(black, 0.7);
          }
        }

        &-quantity {
          display: grid;
          margin: 0;
          padding: 0;
          grid-template-areas: "input plus" "input minus";
          place-items: center;

          &__link {
            // position: absolute;

            &.plus {
              position: relative;
              grid-area: plus;
              height: 20px;
              width: 20px;
              transition: all 0.1s;
              transition-timing-function: ease-in;
              // border: 2px solid blue;
              cursor: pointer;

              &:active {
                border: 2px solid grey;
                border-radius: 100px;
              }

              &::after,
              &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: black;
                transition: all 0.2s;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
              }

              &::after {
                transform: translateX(-50%) rotate(90deg);
              }
            }

            &.minus {
              grid-area: minus;
              position: relative;
              transition: all 0.1s;
              transition-timing-function: ease-in;
              border: 2px solid transparent;
              height: 20px;
              width: 20px;
              cursor: pointer;

              &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                background-color: black;
                display: inline-block;
                transition: all 0.2s;
              }

              &:active {
                border: 2px solid grey;
                border-radius: 100px;
              }
            }
          }

          & > input {
            grid-area: input;
            width: 3ch;
            height: 4ch;
            text-align: center;
            font-weight: 700;
            display: flex;
            padding: 0;
            margin: 0;
            color: rgba(black, 0.6);
            // padding-right: 10px;
            font-size: 2rem;
          }
        }

        &-price {
          font-size: 1.7rem;
          font-weight: 300;
          color: teal;
        }

        &-remove {
          font-size: 3rem;
          background: white;
          border: 0;
          color: darken(darkgrey, 0.5);
          padding: 0;
          margin: 0;
          transition: all 0.2s;

          &:active {
            text-shadow: 0 0 1px rgba(black, 0.6);
          }
        }
      }
    }
  }

  .checkout-button {
    background-color: rgba(#e7c569, 0.9);
    align-self: center;
    justify-self: center;
    border-radius: 100px;
    width: 50%;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 3px 8px rgba(black, 0.6);
    transition: all 0.2s;
    font-size: 2rem;
    padding: 5px 0;

    &:active {
      background-color: lighten(rgba(#e7c569, 0.4), 3%);
      box-shadow: 0 3px 4px rgba(black, 0.8);
      transform: translateY(5%);
    }
  }
}
