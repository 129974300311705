.navigation {
  &__checkbox {
    display: none;
  }

  &__button {
    background-color: $white;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 6rem;
    right: 6rem;
    border-radius: 50%;
    z-index: $z-high3;
    box-shadow: 0 1.5rem 3rem rgba($black_coffee, 0.6);
    text-align: center;

    @include respond(tab-port) {
      top: 5rem;
      right: 5rem;
    }

    @include respond(phone) {
      top: 4rem;
      right: 4rem;
    }
  }

  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient($light-pink, $light-green);
    z-index: $z-high1;
    transition: transform 0.2s cubic-bezier(0.83, 0, 0.17, 1);

    @include respond(tab-port) {
      top: 5.5rem;
      right: 5.5rem;
    }

    @include respond(phone) {
      top: 4.5rem;
      right: 4.5rem;
    }
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: $z-high2;
    opacity: 0;
    width: 0;
    transition: all 0.2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  &__list {
    @include center-element;
    list-style: none;
    text-align: center;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    padding: 2rem;

    &:link,
    &:visited {
      text-transform: uppercase;
      font-size: 3rem;
      text-decoration: none;
      color: $white;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        $white 50%
      );
      background-size: 240%;
      transition: all 0.4s;
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $black_coffee;
    }
  }

  // FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
  }

  &__icon {
    position: relative;
    margin-top: 3.4rem;

    &,
    &::after,
    &::before {
      width: 3rem;
      height: 2px;
      background-color: $black_coffee;
      display: inline-block;
      transition: all 0.2s;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 0;
    }

    &::before {
      top: 0.9rem;
    }

    &::after {
      top: -0.9rem;
    }
  }

  &__button:hover &__icon::before {
    top: 1.1rem;
  }

  &__button:hover &__icon::after {
    top: -1.1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
    transform: rotate(180deg);
  }

  &__checkbox:checked + &__button &__icon:before {
    transform: rotate(45deg);
    top: 0;
  }

  &__checkbox:checked + &__button &__icon:after {
    transform: rotate(-45deg);
    top: 0;
  }

  &__checkbox:not(:checked) ~ &__nav &__link {
    pointer-events: none;
  }
}
