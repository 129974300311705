/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 21, 2020 */

@font-face {
  font-family: "bhatoshai_capsregular";
  src: url("bhatoshine_caps-webfont.woff2") format("woff2"),
    url("bhatoshine_caps-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bhatoshai_capsregular";
  src: url("bhatoshine_caps-webfont.woff2") format("woff2"),
    url("bhatoshine_caps-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bhatoshineregular";
  src: url("bhatoshine_reguler-webfont.woff2") format("woff2"),
    url("bhatoshine_reguler-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bhatoshineregular";
  src: url("bhatoshine_reguler-webfont.woff2") format("woff2"),
    url("bhatoshine_reguler-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
