@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* MEDIA QUERIES
0 - 600px : phone
600 - 900px : tablet
900 - 1200px : tablet landscape
1200 - 1800px : Normal style applies ( Default )
1800px + : Big Desktop

Breakpoint choices
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px

ORDER: Base -> typography -> general layout + grid -> page layout -> components
*/

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      // 600px
      @content;
    }
  }

  @if $breakpoint==phone-land {
    @media only screen and (max-width: 46.25em) {
      // 600px
      @content;
    }
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      // 900px
      @content;
    }
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 112em) {
      @content;
    }
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}