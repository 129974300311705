 /* Dropdown Button */
 .dropbtn {
    background-color: #A3BD5F;
    color: white;
    padding: 10px 16px;
    font-size: inherit;
    border: 1px solid black;
    cursor: pointer;
    // border-radius: 10px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    cursor: pointer;
    display: none;
    position: absolute;
    background-color: #A3BD5F;
    // min-width: 100%;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
    padding: 10px;
    list-style: none;
    &-item {
      font-size: 2rem;
      text-align: center;
      min-width: 150px;
      border-radius: 20px;
      place-items: center;


      &:hover {
          background-color: darken(#A3BD5F, 10%);
      }
    }

    
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #3e8e41;} 

  /* Links inside the dropdown */
//   .dropdown-content a {
//     color: black;
//     padding: 12px 16px;
//     text-decoration: none;
//     // display: block;

//     &:hover {
//         /* Change color of dropdown links on hover */
//         background-color: #ddd;
//     }
//   }
  
  
  
  