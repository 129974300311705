///////////////////////
// Z-INDEXES
//////////////////////
$z-low: -1;
$z-medium: 0;
$z-high: 1;
$z-high1: 10;
$z-high2: 20;
$z-high3: 30;
///////////////////////
// COLORS
//////////////////////
$cedar_chest: #db504a;
$camel: #cd9c6a;
$light_green: #a4be5f;
$light_pink: #de7f73;
$beige: #e6c569;
$brown-font: #82623d;
$black_coffee: #3f322d;
$prussian_blue: #003049;
$steel-teal: #5e8485;
$cambridge-blue: #bcd8c1;
$mauve: #ddb4f6;
$light-beige: #fff9e6;
$dark_grey: #555;
$dark-grey2: #444;
$dark-grey3: #222;
$light_grey: #f7f7f7;
$white: #fff;

///////////////////////
// GRID
//////////////////////
$grid_width: 114rem;
$grid_width_tab_port: 80rem;
$grid_width_phone_port: 50rem;
$gutter_vertical: 8rem;
$gutter_horizontal: 6rem;
$gutter_vertical: 8rem;
$gutter_vertical_small: 6rem;
$gutter_vertical_xsmall: 2rem;

///////////////////////
// FONTS
//////////////////////

$default-font-size: 1.6rem;
