@import "../sass/abstracts/variables";

#avatar-img {
  border-radius: 100%;
}

.shop-header {
  position: relative;
  display: flex;
  text-align: center;
  text-transform: uppercase;
  padding: 7rem 3rem;

  justify-content: space-around;
  place-items: center;
  background-image: url("./assets/MeninasDoceiras-Capa-SemLogo.svg");
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position: center top;

  &__item {
    text-decoration: none;
    color: black;
    // color: darken(#81623e, 10%);
    padding: 0 3rem;
    i {
      font-size: 4rem;
      text-shadow: 5px 5px 3.5px rgba(grey, 1);
    }

    p {
      font-size: 2rem;
    }
    .avatar-container {
      img {
        box-shadow: 5px 5px 3.5px rgba(grey, 1);
        width: 4rem;
      }
      p {
        font-size: 1.9rem;
        text-shadow: 5px 5px 3.5px rgba(grey, 1);
      }
    }
  }

  &__logo {
    flex-grow: 1;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30%;
    }
  }

  ///////////////////////
  // CART BOX
  //////////////////////
  &__cart {
    position: relative;
    cursor: pointer;
    color: $light_green;

    p {
      color: black;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .cart-items {
    // background-color: white;
    color: black;
    font-size: 2.5rem;
    font-weight: 700;
    // text-shadow: 1px 1px black;
    text-shadow: 5px 5px 3.5px rgba(black, 0.4);
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-25%, 0);
  }
}