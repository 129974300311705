.footer {
  display: flex;
  align-items: flex-end;
  height: 10vh;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $black_coffee;
  padding: 0 3rem;
  font-size: 1.4rem;

  &__logo-about {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer__logo-box {
      display: flex;
      justify-content: center;

      // height: 15rem;
      // width: 20rem;
      // margin: 0 auto;
    }

    .footer__logo {
      // height: 1rem;
      width: 100px;
    }
    .footer__navigation {
      border-top: 3px solid $white;
      display: inline-block;

      @include respond(tab-port) {
        width: 100%;
        text-align: center;
        padding-top: 1rem;
      }

      @include respond(phone) {
        width: 100%;
        text-align: center;
        padding-top: 1rem;
      }
    }
  }

  &__list {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }

    @include respond(phone) {
      width: 100%;
      text-align: center;
    }
  }

  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      color: $light_grey;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover,
    &:active {
      color: $light_green;
    }
  }

  &__socialmedia-copyright {
    // font-size: 2rem;
    display: flex;

    flex-direction: column;

    .copyright {
      margin: 0;
      padding: 0;
      border-top: 3px solid white;

      p {
        margin: 0;
      }
    }

    .socialmedia {
      font-size: 4rem;
      margin: 0;
      padding: 0;
    }
    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }

    @include respond(phone) {
      width: 100%;
      text-align: center;
    }
  }
}
