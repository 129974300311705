/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 21, 2020 */



@font-face {
    font-family: 'terfensregular';
    src: url('terfens_regular-webfont.woff2') format('woff2'),
         url('terfens_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}