body {
  overflow: hidden;
  margin: 0;
}
$color-green: #a3bd5f;
$color-brown: #81623e;
$color-pink: #dd7f72;
$color-yellow: #e5c468;

.shop-main {
  .shop-content__filter {
    // position: relative;
    padding: 0;
    // z-index: 10;
    // // background: url("./assets/dripping_chocolate.svg");
    // // background-position-y: -250px;
    // // background-size: contain;
    // height: 50px;
    // // width: 100%;

    .filter-list {
      position: relative;
      top: -100%;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 10px;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      font-size: 3rem;
      background-color: transparent;

      &-item {
        
        &-input {
          display: none;
        }
        
        &-label {
          cursor: pointer;
          background-color: white;
          border-radius: 10px;
          padding: 10px 16px;
        }
        
        &-label:hover {
          background-color: #DD7F72;
          border-radius: 10px;
          padding: 10px 16px;
        }

        &-input:checked ~ .filter-list-item-label {
          background-color: #A3BD5F;
        }
      }
      // border-top: 2px solid #81623e;
      // background: linear-gradient(transparent 50%, rgba(#81623e, 1) 90%),
      //   linear-gradient(
      //     to right,
      //     rgba($color-yellow, 1) 13%,
      //     rgba($color-green, 1) 17%,
      //     rgba($color-pink, 1) 20%,
      //     rgba($color-yellow, 1) 27%,
      //     rgba($color-green, 1) 29%,
      //     rgba(white, 1) 30%,
      //     rgba($color-yellow, 1) 32%,
      //     rgba(white, 1) 40%,
      //     rgba(white, 1) 60%,
      //     rgba($color-pink, 1) 70%,
      //     rgba($color-pink, 1) 73%,
      //     rgba($color-green, 1) 75%,
      //     rgba($color-yellow, 1) 80%,
      //     rgba($color-green, 1) 83%,
      //     rgba($color-pink, 1) 90%
      //   );
    }
  }
}

@import "./Header";
@import "./checkout";
@import "./footer";
@import "./content";
@import "./MainShopButton";
@import "./drowdown";
