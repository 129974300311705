$container-height: 70vh;

.login-signup {
  display: flex;
  height: 100vh;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    align-items: center;
  }

  &-container {
    position: relative;
    display: flex;
    column-gap: 30px;
    margin: 0 auto;
    justify-content: center;
    align-items: stretch;
    box-shadow: 2rem 2rem 2rem rgba($brown-font, 0.7);
    border: 2px solid $black_coffee;
    border-radius: 20px;
    padding: 2rem;

    @media only screen and (max-width: 1000px) {
      width: 450px;
      height: clamp(300px, 100%, 600px);
      flex-wrap: wrap;
      overflow: hidden;
      align-items: flex-end;
    }
  }
}

.login-signup-menu {
  display: flex;
  justify-content: flex-end;
  // align-items: baseline;
  font-size: 3rem;
  width: 100%;
  // height: 10%;
  z-index: 100;

  @media only screen and (min-width: 1000px) {
    display: none;
  }

  &-group {
    display: flex;
    margin-right: 10px;
    background-color: rgba(grey, 0.2);
    // clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
    transform: skewX(-10deg);
    color: black;
    box-shadow: 10px 5px 5px 5px rgba(black, 0.4);

    input {
      position: absolute;
      opacity: 0;
    }
    label {
      transition: all 0.5s;
      padding: 1rem 3rem;
    }

    input:checked + label {
      background-color: $light_green;
    }
  }
}

.login-container {
  @keyframes fadein {
    from {
      opacity: 0;
      // background-color: red;
    }
    to {
      opacity: 1;
      // background-color: yellow;
    }
  }
  animation-name: fadein;
  animation-duration: 1s;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 2rem;

  font-size: 2rem;
  background-color: rgba($light_green, 0.3);
  border-radius: 20px;
  box-shadow: 1.5rem 3rem 2rem rgba(black, 0.3);
  @media only screen and (max-width: 1000px) {
    position: absolute;
    top: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  &-form {
    width: 90%;
    row-gap: 2rem;
    display: flex;
    flex-direction: column;
    // background-color: rgba(blue, 0.2);

    &-title {
      text-transform: uppercase;
      color: black;
    }

    &-group {
      input {
        width: 100%;
        padding-left: 1rem;
      }
    }

    &-forgot {
      color: inherit;
      text-decoration: none;
    }

    &-check {
      font-size: 2rem;
      display: flex;
      place-items: center;

      &-label {
        background-color: rgba(green, 0.2);
      }
      &-input {
        flex-basis: 50px;
        margin-right: 1rem;
      }
    }
  }

  & input {
    font-size: 1.5rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid black;
  }
}

.login-header {
  & > h1 {
    font-size: 4rem;
    color: black;
  }
}

.login-image {
  background-image: url("../sass/img/men_doc/MeninasDoceiras-Capa-Logo.png");
  height: 100px;
}

.fail-login {
  font-size: 1.5rem;
  color: red;
}
