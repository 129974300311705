.shop-content {
  overflow: scroll;
  text-align: center;
  position: relative;
  padding-top: 10px;
  // padding-bottom: 10vh;
  height: 90vh;
  display: grid;
  place-items: center;
  width: 100%;
  background-image: linear-gradient(white, transparent),
    url("./assets/Colorful-Stingrays.svg");
  background-blend-mode: lighten;

  & h2 {
    font-size: 4rem;
  }

  & p {
    font-size: 2rem;
  }
}

.candy-section {
  width: clamp(500px, 100%, 1200px);
  color: blue;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1rem;
  padding-bottom: 25vh;

  &-cards {
    // overflow: auto scroll;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    // display: grid;
    // grid: auto-flow dense;
    // grid-template-rows: 1fr;

    place-content: center;
    place-items: flex-start;
    width: 100%;
    padding-bottom: 10rem;
    // background-color: blue;

    // padding: 2rem;
    // background-color: blue;

    .product-card {
      border: 1px solid grey;
      border-radius: 10px;
      background-color: white;
      box-shadow: 2px 2px 4px 4px rgba($light_green, 0.4);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: nowrap;
      min-height: 450px;
      width: clamp(300px, 100%, calc(100% / 3.5));
      padding: 1rem;
      .photos-container {
        position: relative;
        margin: 0 auto;
        max-width: 100%;
        box-shadow: 2px 0 4px 4px rgba(black, 0.4);
        .mySlides {
          // display: flex;
          position: relative;
          // flex-wrap: nowrap;
          // flex-direction: row;
          overflow: hidden;
          padding-top: 1rem;

          .slide1 {
            // width: 0%;
            // display: none;
          }

          .slide2 {
            // width: 100%;
          }

          .slide3 {
            // display: none;
            // width: 0%;
          }
        }

        .prev,
        .next {
          z-index: 10;
          cursor: pointer;
          position: absolute;
          top: 50%;
          color: white;
          font-weight: bold;
          font-size: 2rem;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;

          &:hover {
            color: rgba(purple, 0.4);
          }
        }

        .prev {
          transform: translate(50%, -50%);
          left: 0%;
        }

        .next {
          transform: translate(-50%, -50%);
          right: 0%;
        }
      }

      .details-container {
        text-align: center;

        h2 {
          font-size: 2rem;
          height: 6.5rem;
        }

        h3 {
          font-size: 2rem;
        }

        p {
          font-size: 1rem;
          padding: 5px;
        }
      }
    }
  }
}

.image-bullets {
  // display: inline-block;
  line-height: 0;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: 5.5rem;

  &::before {
    color: grey;
    left: 10%;
    content: "•";
  }

  &::after {
    color: grey;
    left: 10%;
    content: "•";
  }
}
